import React from "react";
import Section from "../Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "../SectionHeader";
import Button from "react-bootstrap/Button";

function CtaSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
        ></SectionHeader>
        {props.showArchieve && <SectionHeader
          title={""}
          subtitle={<a href="https://tinyletter.com/HarshMakadia/archive" rel="noopener noreferrer" target="_blank">To view archievd newsletter</a>}
          size={1}
          spaced={true}
        ></SectionHeader>}
        <Button
          variant={props.buttonColor}
          size="lg"
          onClick={props.buttonOnClick}
        >
          {props.buttonText}
        </Button>
      </Container>
    </Section>
  );
}

export default CtaSection;
